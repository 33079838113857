<template>
    <div class="h-screen overflow-hidden bg-gray-500/10">
        <nav class="flex flex-wrap items-center justify-between fixed top-0 z-40 w-full backdrop-blur shadow-sm dark:bg-black p-2 bg-white">
            <Logo />
            <UButton :icon="!isDark ? 'i-heroicons-moon-20-solid' : 'i-heroicons-sun-20-solid'" variant="ghost" aria-label="Theme" @click="isDark = !isDark" />
            <UTooltip text="Novidades" class="hidden ">
                <UButton id="beamerBt" icon="i-heroicons-newspaper" variant="ghost"/>
            </UTooltip>
        </nav>
        <div class="container mx-auto max-w-screen-sm pt-12">
            <div class="">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig();
const colorMode = useColorMode();

const isDark = computed({
    get() {
        return colorMode.value === 'dark'
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
    }
})

toggleJivoChatVisibility(false)
</script>